import { BaseGenerationState } from './code';
import { type TranscriptPassage } from './transcript-passage';

export enum InterviewState {
  NO_RECORDING,
  IN_PROGRESS,
  UPLOADED_NO_ANSWERS,
  UPLOADED,
  UPLOAD_ERROR_CORRUPT,
  UPLOAD_ERROR_TIMEOUT
}

export enum SuggestedQuestionState {
  NOT_TRIGGERED,
  IN_PROGRESS,
  COMPLETE
}

export enum InterviewUploadedFileState {
  UPLOADED = "UPLOADED",
  ERROR_FILE_CORRUPT = "ERROR_FILE_CORRUPT",
  DELETED = "DELETED" // NOT USED RIGHT NOW
}

export enum InterviewTranscriptState {
  WAITING = "WAITING",
  TRANSCRIBING = "TRANSCRIBING",
  PARSING = "PARSING",
  FINISHED = "FINISHED",
  ERROR_UNKOWN = "ERROR_UNKOWN", // NOT USED RIGHT NOW
}

export interface IInterview {
  id: string | null;
  // Fields (in the document)
  text: string;
  speaker_transcript?: { speaker: string, text: string, time: string }[] | null | undefined;
  interviewee: string;
  date: Date;
  state: InterviewState;
  pendingReviews: number;
  transcript_passages: TranscriptPassage[];
  suggestedQuestionState: SuggestedQuestionState;
  suggestedQuestions: string[];
  // References (in the document)
  user_id: string;
  codesState: BaseGenerationState;
  persona: string;
  // new state fields
  uploadedFileState?: InterviewUploadedFileState;
  transcriptState?: InterviewTranscriptState;
  remainingVEGenerationCount?: number;
}

export class Interview {
  id: string | null = "";
  // Fields (in the document)
  text: string = "";
  speaker_transcript?: { speaker: string, text: string, time: string }[] | null | undefined;
  interviewee: string = "";
  date: Date = new Date();
  state: InterviewState = InterviewState.NO_RECORDING;
  suggestedQuestionState: SuggestedQuestionState = SuggestedQuestionState.NOT_TRIGGERED;
  codesState: BaseGenerationState = BaseGenerationState.NOT_TRIGGERED;
  suggestedQuestions: string[] = [];
  deletedSuggetedQuestions: string[] = [];
  pendingReviews: number = 0
  transcript_passages: TranscriptPassage[] = []
  // References (in the document)
  user_id: string = "";
  persona: string = "";
    // new state fields
  uploadedFileState?: InterviewUploadedFileState;
  transcriptState?: InterviewTranscriptState;
  remainingVEGenerationCount?: number;
  errorTranscriptionMessage?: string;
  errorFileUploadMessage?:string;
}
