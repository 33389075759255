import { createI18n } from 'vue-i18n';
import { loadLocale } from './loadLocale';

const messages = {};

const i18n = createI18n({
  locale: 'en',
  messages,
  silentTranslationWarn: true
});

export default i18n;

export async function loadInitialLocale(locale: string) {
  //@ts-expect-error localization
  i18n.global.setLocaleMessage(locale, await loadLocale(locale));
  i18n.global.locale = locale;
}
