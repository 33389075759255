
import { BaseGenerationState } from './code';
import { type Interview } from './interview';
import { type Note } from './note';
import { type Question } from './question';
import { type QuestionSynthesis } from './question-synthesis';
import { SuggestedQuestion } from './suggested-question';

export interface IProject {
  id: string | null;
  // Fields in the document
  language: string;
  name: string;
  is_generating_answers: boolean | null;
  is_generating_synthesis: boolean | null;
  description: string | null;
  // Subcollections (not in the document)
  interviews: Interview[];
  notes: Note[];
  questions: Question[];
  question_syntheses: QuestionSynthesis[];
  suggested_questions: SuggestedQuestion[];
  themesState: BaseGenerationState;
}
export class Project {
  id: string = "";
  language: string = "";
  name: string = "";
  is_generating_answers: boolean | null = false;
  description: string = "";
  // Subcollections (not in the document)
  interviews: Interview[] = [];
  notes: Note[] = [];
  questions: Question[] = [];
  question_syntheses: QuestionSynthesis[] = [];
  suggested_questions?: SuggestedQuestion[] = [];
  themesState: BaseGenerationState = BaseGenerationState.NOT_TRIGGERED;
}
