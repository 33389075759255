export interface ICode {
    text: string;
}

export class Code implements ICode {
    text = "";
}

export enum BaseGenerationState {
    NOT_TRIGGERED,
    IN_PROGRESS,
    COMPLETE,
    COMPLETED_WITH_ERRORS
  }
  