import { Question, QuestionSynthesisError } from 'types/question';
import { type QuestionSynthesis } from 'types/question-synthesis';
import { type QuestionSynthesisNote } from 'types/question-synthesis-note';
import { useWorkspaceStore } from './workspaceStore';

export const useSynthesisStore = defineStore('synthesis', {
  state: () => ({
    questions: [] as Question[],
    synthesis: [] as QuestionSynthesis[],
    unsubscribeFromSynthesis: null as any, // eslint-disable-line
    questionsProjectId: '' as string,
    SynthesisProjectId: '' as string,
    synthesisInProgress: new Set<string>(),
    removedNoteSynthesis: null as QuestionSynthesis | null,
    unsubscribeFromQuestion: null as any, // eslint-disable-line
    db: useFirestore()
  }),
  actions: {
    async subToQuestions() {
      if (this.questions.length && this.questionsProjectId == useProjectStore().project.id) return;
      this.questionsProjectId = useProjectStore().project.id;
      this.questions = [];
      const questionsQuery = query(
        collection(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/questions`
        ),
        orderBy('index')
      );

      this.unsubscribeFromQuestion = onSnapshot(questionsQuery, async (questionQuerySnapshot) => {
        questionQuerySnapshot.forEach((doc) => {
          if (doc.id) {
            const questionFromDB: Question = {
              id: doc.id,
              text: doc.data().text,
              only_here: doc.data().only_here,
              interviewId: doc.data().interviewId,
              index: doc.data().index,
              synthesis_is_generating: doc.data().synthesis_is_generating,
              synthesis_is_waiting: doc.data().synthesis_is_waiting,
              synthesis_error: doc.data().synthesis_error as QuestionSynthesisError
            };
            const foundQuestionAndAnswerElementIndex = this.questions.findIndex(
              (q) => q.id === doc.id
            );
            if (foundQuestionAndAnswerElementIndex != -1) {
              this.questions[foundQuestionAndAnswerElementIndex] = questionFromDB;
              if (
                questionFromDB?.synthesis_error != null &&
                questionFromDB?.synthesis_error != QuestionSynthesisError.NO_ERROR
              ) {
                this.synthesisInProgress.delete(doc.id);
              }
            } else this.questions.push(questionFromDB);
          }
        });
      });
    },
    async createQuestion(text: string) {
      const questionIndex = this.questions.length
        ? this.questions[this.questions.length - 1].index + 1000
        : 0;

      // const questionToAdd = {
      //   id: 'newQuestion',
      //   text,
      //   index: questionIndex
      // };

      // this.questions.push(questionToAdd as Question);

      const addedQuestion = await addDoc(
        collection(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/questions`
        ),
        { text, index: questionIndex, only_here: false }
      );
      const addedQuestionInList = this.questions.find((q) => q.id == 'newQuestion');
      if (addedQuestionInList) addedQuestionInList.id = addedQuestion.id;
    },
    async subscribeToSynthesis() {
      if (
        this.unsubscribeFromSynthesis &&
        useProjectStore().project.id != this.SynthesisProjectId
      ) {
        this.SynthesisProjectId = useProjectStore().project.id;
        this.unsubscribeFromSynthesis();
        this.unsubscribeFromSynthesis = null;
      }
      const synthesisQuery = collection(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/question_syntheses`
      );
      this.unsubscribeFromSynthesis = onSnapshot(synthesisQuery, async (synthesisQuerySnapshot) => {
        synthesisQuerySnapshot.docChanges().forEach((change) => {
          if (change.type === 'removed') {
            this.synthesis = this.synthesis.filter((s) => s.id != change.doc.id);
            return;
          } else {
            const synthesisElementObject = {
              id: change.doc.id,
              ...change.doc.data()
            } as QuestionSynthesis;
            synthesisElementObject.notes = synthesisElementObject.notes?.sort(
              (a, b) => a.index - b.index
            );

            const foundSynthesisElementIndex = this.synthesis.findIndex(
              (s) => s.id == change.doc.id
            );
            if (foundSynthesisElementIndex != -1)
              this.synthesis[foundSynthesisElementIndex] = synthesisElementObject;
            else this.synthesis.push(synthesisElementObject);
            this.synthesisInProgress.delete(synthesisElementObject.questionId);
          }
        });
      });
      // setTimeout(() => {
      //   this.synthesisInProgress.clear()
      // }, 3000);
    },
    async deleteSynthesis(id: string) {
      const synthesisDoc = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/question_syntheses`,
        id
      );
      this.synthesis = this.synthesis.filter((s) => s.id != id);
      deleteDoc(synthesisDoc);
    },
    async createSynthesis(text: string, questionId: string) {
      await addDoc(
        collection(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/question_syntheses`
        ),
        {
          text,
          user_created: true,
          index: this.synthesis.filter((s) => s.questionId == questionId)?.length ?? 0 + 1,
          questionId
        }
      );
    },
    async updateSynthesis(synthesis: QuestionSynthesis) {
      if (synthesis.id) {
        const synthesisRef = doc(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/question_syntheses`,
          synthesis.id
        );
        await updateDoc(synthesisRef, {
          text: synthesis.text
        });
      }
    },
    async moveNoteToOtherSynthesis(
      newSynthesis: QuestionSynthesis,
      note: QuestionSynthesisNote,
      index: number
    ) {
      if (
        !this.removedNoteSynthesis ||
        !this.removedNoteSynthesis.id ||
        !newSynthesis.id ||
        !note.id
      ) {
        return;
      }
      //delete note from old synthesis
      this.removedNoteSynthesis.notes = this.removedNoteSynthesis.notes.filter(
        (n) => n.id != note.id
      );
      const questionRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/question_syntheses`,
        this.removedNoteSynthesis.id
      );
      await updateDoc(questionRef, {
        notes: this.removedNoteSynthesis.notes
      });
      this.removedNoteSynthesis = null;

      //add note to new synthesis
      note.index = index;
      // newSynthesis.notes.push(note);
      newSynthesis.notes = newSynthesis.notes.sort((a, b) => a.index - b.index);
      const newSynthesisRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/question_syntheses`,
        newSynthesis.id
      );
      await updateDoc(newSynthesisRef, {
        notes: newSynthesis.notes
      });
    },
    async updateNoteIndex(
      synthesis: QuestionSynthesis,
      note: QuestionSynthesisNote,
      index: number
    ) {
      if (!synthesis || !synthesis.id || !note.id) {
        return;
      }
      const noteInSynthesis = synthesis.notes.find((n) => (n.id = note.id));
      if (noteInSynthesis) noteInSynthesis.index = index;
      const noteToUpdateRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/question_syntheses`,
        synthesis.id
      );
      await updateDoc(noteToUpdateRef, {
        notes: synthesis.notes
      });
    },
    async alterNoteValidationStatus(synthesis: QuestionSynthesis, noteId: string) {
      if (synthesis.id) {
        const note = synthesis.notes.find((n) => n.id == noteId);
        if (note) note.user_approved = !note.user_approved;
        const synthesisRef = doc(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${useProjectStore().project.id}/question_syntheses`,
          synthesis.id
        );
        await updateDoc(synthesisRef, {
          notes: synthesis.notes
        });
      }
    }
  }
});
