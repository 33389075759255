export enum QuestionSynthesisError {
    NO_ERROR,
    NO_INPUT_NOTES,
    GENERATION_FAILED
}

export interface IQuestion {
    id?: string;
    // Fields (in the document)
    text: string;
    only_here: boolean;
    index: number;
    interviewId: string | null;
    synthesis_is_generating?: boolean | null;
    synthesis_is_waiting?: boolean | null;
    synthesis_error?: QuestionSynthesisError | null;
}

export class Question implements IQuestion {
    id? = "";
    text = "";
    only_here = false;
    index = 0;
    interviewId = null;
    synthesis_is_generating = false;
    synthesis_is_waiting = false;
    synthesis_error = QuestionSynthesisError.NO_ERROR;
}
