import Papa from 'papaparse';
/* eslint-disable @typescript-eslint/no-explicit-any */
export async function loadLocale(locale: string) {
  return new Promise((resolve, reject) => {
    Papa.parse(`/localizations.csv`, {
      download: true,
      header: true,
      complete: function (result) {
        resolve(
          result.data.reduce((messages: any, row: any) => {
            messages[row.key] = row[locale];
            return messages;
          }, {})
        );
      },
      error: function (error: any) {
        reject(error);
      }
    });
  });
}
