import { Project } from 'types/project';
import { SuggestedQuestion } from 'types/suggested-question';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const useProjectStore = defineStore('project', {
  state: () => ({
    project: {} as Project,
    projects: [] as Project[],
    db: useFirestore(),
    projectSubscription: null as any,
    refreshClicked: false as boolean
  }),
  actions: {
    async getprojectList() {
      console.log('getprojectList');
      if (this.projects.length) return;
      const projectsRef = collection(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/`
      );
      (await getDocs(projectsRef)).forEach((projectInFirebase) => {
        this.projects.push({ id: projectInFirebase.id, ...projectInFirebase.data() } as Project);
        // console.log('project',projectInFirebase.data())}
      });
    },
    async subscribeToProject(id: string) {
      console.log('fireProject');
      if (id == this.project.id) return;
      if (this.projectSubscription) this.projectSubscription();
      const projectRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects`,
        id
      );
      this.project.id = id;
      this.projectSubscription = onSnapshot(projectRef, (fireProject) => {
        console.log('project Updated ', fireProject.data());
        this.project = { id: fireProject.id, ...fireProject.data() } as Project;
        this.refreshClicked = this.project.is_generating_answers ?? false;
      });
    },
    async deleteSuggestion(suggested: SuggestedQuestion) {
      if (suggested.text) {
        this.project.suggested_questions =
          this.project.suggested_questions?.filter((sq) => sq.text != suggested.text) ?? [];
        console.log(this.project.question_syntheses);
        const projectRef = doc(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/`,
          this.project.id
        );
        await updateDoc(projectRef, {
          suggested_questions: this.project.suggested_questions
        });
      }
    },
    async refreshFunction(interviewId: string) {
      this.refreshClicked = true;
      const functions = useFirebaseFunctions();
      const refreshFunction = httpsCallable(functions, 'triggerAnswerRefresh');
      refreshFunction({
        workspaceId: useWorkspaceStore().workspace.id,
        projectId: this.project.id,
        interviewId: interviewId
      })
        .then((result) => {
          console.log('Refreshed:', result);
          if ((result.data as { status: number }).status == 309) {
            this.refreshClicked = false;
          }
        })
        .catch((reason) => {
          console.error('Failed to refresh:', reason);
        });
    },
    async createProject(project: Project) {
      const createdProject = await addDoc(
        collection(this.db, `/workspaces/${useWorkspaceStore().workspace.id}/projects/`),
        {
          name: project.name ?? '',
          language: project.language,
          description: project.description
        }
      );
      project.id = createdProject.id;
      this.projects.push(JSON.parse(JSON.stringify(project)));
      return createdProject.id;
    },
    async delete() {
      const projectDoc = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects`,
        this.project.id
      );
      this.projects = this.projects.filter((p) => p.id != this.project.id);
      await deleteDoc(projectDoc);
    }
  }
});
