import { initializeApp } from 'firebase/app';
import config from './firebase.json';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';

const app = initializeApp(config);
const functions = getFunctions(app, 'europe-west3');
if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(getAuth(app), 'http://127.0.0.1:9099');
  connectFirestoreEmulator(getFirestore(app), '127.0.0.1', 9399);
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  connectStorageEmulator(getStorage(app), '127.0.0.1', 9199);
  connectDatabaseEmulator(getDatabase(app), '127.0.0.1', 9000);
}

export const firebaseApp = app;

export function useFirebaseFunctions() {
  return functions;
}
