import { type User, type Workspace } from 'types/workspace';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const useWorkspaceStore = defineStore('Workspace', {
  state: () => ({
    db: getFirestore(),
    workspace: {} as Workspace,
    personas: [] as string[],
  }),
  actions: {
    async getWorkspace() {
      try {
        let workspaceId = '';
        const currentUser = await getCurrentUser();
        const tokenResult = await currentUser?.getIdTokenResult();
        console.log('tokenResult', tokenResult)
        if (!tokenResult || !tokenResult?.claims?.mindwaves) {
          // This is a sign-up because we don't have the claims set so the workspace ID is the same as the user's.
          workspaceId = currentUser?.uid as string
        } else {
          workspaceId = (tokenResult.claims.mindwaves as { workspaceId: string })
            .workspaceId as string;
        }
        console.log('worskpaceId---', workspaceId)
        if (this.workspace && this.workspace.id == workspaceId) return;
        if (workspaceId) {
          const workspaceRef = await getDoc(doc(this.db, `/workspaces/${workspaceId}`));
          this.workspace = { id: workspaceRef.id, ...workspaceRef.data() } as Workspace;
          this.workspace.users = [];
          const usersCollectionRef = collection(this.db, `workspaces/${this.workspace.id}/users`);
          const userSnapshot = await getDocs(usersCollectionRef);
          userSnapshot.forEach((userDoc) => {
            this.workspace.users.push({
              id: userDoc.id,
              ...(userDoc.data() as Omit<User, 'id'>)
            });
          });

          this.workspace.personas = [];
          const personasCollectionRef = collection(this.db, `workspaces/${this.workspace.id}/personas`);
          const personasSnapshot = await getDocs(personasCollectionRef);
          personasSnapshot.forEach((personaDoc) => {
            this.workspace.personas.push((personaDoc.data().persona as string));
          });
        }
      } catch (e) {
        console.log('e', e);
      }
    },
  }
});
