import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { VueFire, VueFireAuth } from 'vuefire';
import { firebaseApp } from './firebase';
import PrimeVue from 'primevue/config';
import i18n, { loadInitialLocale } from '@/localization/i18n';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
import Tooltip from 'primevue/tooltip';
import App from './App.vue';
import router from './router';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/aura-light-green/theme.css';
import 'primeicons/primeicons.css';
// import * as Sentry from '@sentry/vue';

import '/node_modules/primeflex/primeflex.css';
import './main.css';

const app = createApp(App);
app.use(router);
app.use(ToastService);
app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()]
});
app.use(PrimeVue);
app.directive('tooltip', Tooltip);

app.use(createPinia());
//localization
app.use(i18n);

// Sentry.init({
//   app,
//   environment: import.meta.env.VITE_APP_ENV || 'local',
//   dsn: 'https://2a4a63328446279465b88fb5138bbfdc@o4508239855353856.ingest.de.sentry.io/4508257484210256',
//   integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

loadInitialLocale('en').then(() => app.mount('#app'));
