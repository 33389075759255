<template>
    <div class="flex">
    <Toast position="top-right"  group="Error" class="transcribed-toast" :style="{'margin-top':progressToastIsShown?'155px':'75px'}">
        <template #message="slotProps">
            <div class="flex flex-column align-items-start p-3 gap-2 w-full">
                    {{ slotProps.message.summary }}
            <span class="text-green-500" >Go to interviews</span>
        </div>
        </template>
    </Toast>
    <Toast position="top-right" group="processing" class="mt-8 transcribed-toast">
    <template #message="slotProps">
        <div class="flex flex-column align-items-start" style="flex: 1">
            <div class="font-medium text-lg my-3 text-900 pl-3">
                <div class="flex gap-4" v-if="(interviewStore.uploadProgressCounter ||  uploadedButNotTranscribedCount || interviewStore.interviews.filter(i => i.transcriptState==InterviewTranscriptState.FINISHED).length != interviewStore.interviews.length)">
                <CircleProgressBar v-if="!(uploadedButNotTranscribedCount < interviewStore.uploadProgressCounter)" class="m-auto" strokeWidth="7" colorUnfilled="#81C700" colorFilled="#81C700"  style="transform: rotate(180deg);width:20px;height:20px" :value="interviewStore.interviews.filter(i => i.transcriptState==InterviewTranscriptState.FINISHED).length" :max="interviewStore.interviews.length" />
                <span >
                    <span v-if="uploadedButNotTranscribedCount < interviewStore.uploadProgressCounter"> {{ uploadedButNotTranscribedCount }}/{{ interviewStore.uploadProgressCounter }} interviews are uploaded</span>
                    <div v-else class="flex flex-column">
                    <span>All interviews are Uploaded.</span>
                    <span>{{interviewStore.interviews.filter(i => i.transcriptState==InterviewTranscriptState.FINISHED).length}}/{{interviewStore.interviews.length}}
                    interviews are transcribed</span>
                </div>
                </span>
                </div>
                <div class="flex gap-4" v-else>
                        <Button icon="pi pi-check" style="height:25px" severity="success" rounded class="m-auto" aria-label="Search" ></Button>
                        <span class="flex flex-column">
                            All interviews are successfully transcribed.
                        </span>
                </div>
            </div>
        </div>
    </template>
    </Toast>
        <!-- <Toast position="top-right" class="mt-8" group="headless" @close="visible = false">
            <template #container="{ message, closeCallback }">
                <section style="color:#718096" class="flex flex-column flex-col p-3 gap-2 w-full rounded-xl">
                    <div class="flex justify-content-between">
                        <dev>
                        <i class="pi pi-cloud-upload mr-2"></i>
                        <span>Uploading for Interviewee:<h4 class="ml-4 mt-2 font-bold">{{useInterviewStore().interviews?.find(i => i.id == message.interviewId)?.interviewee}}</h4> </span>
                    </dev>
                    <Button icon="pi pi-times" text severity="danger" rounded aria-label="Cancel" @click="closeCallback" />
                    </div>
                    <div v-if="props.uploadProgress[message.interviewId]!=100" class="flex flex-column gap-2">
                        <ProgressBar
                        :value="props.uploadProgress[message.interviewId]"
                        :showValue="false"
                        :style="{ height: '4px' }"
                        ></ProgressBar>
                        <label>{{ Math.floor(props.uploadProgress[message.interviewId]) }}% uploaded</label>
                    </div>
                    <div v-else>
                        <div class="flex gap-1">
                            <img style="width:15px" alt="logo" src="/green-check.svg"  /> <span style="color:#3CAD69;top:5px">Upload Done</span>
                        </div>
                        <div class="mt-1" v-if="useInterviewStore().interviews?.find(i => i.id == message.interviewId)?.state == InterviewState.IN_PROGRESS">
                            <ProgressSpinner style="top:2px;width:15px;height:15px"/>
                            <span style="top:1px">
                            Transcription In Progress
                            <span class="one">.</span>
                            <span class="two">.</span>
                            <span class="three">.</span></span>
                        </div>
                        <div class="mt-1 flex gap-1" v-if="useInterviewStore().interviews?.find(i => i.id == message.interviewId)?.state == InterviewState.UPLOADED_NO_ANSWERS">
                            <img style="width:15px" alt="logo" src="/green-check.svg"  /> <span style="color:#3CAD69;top:5px">Processing Done</span>

                        </div>

                    </div>
                    </section>
            </template>
        </Toast> -->
        </div>
</template>

<script setup >
import { useToast } from "primevue/usetoast";
import { ref, onUnmounted } from 'vue';
import { InterviewState, InterviewUploadedFileState, InterviewTranscriptState } from 'types/interview';
import { CircleProgressBar } from 'vue3-m-circle-progress-bar';
const toast = useToast();
const visible = ref(false);
const oldInterviews = ref([])
const interviewStore=useInterviewStore();
const progressToastIsShown= ref(false);
const uploadedButNotTranscribedCount= ref (0);
watch(()=> interviewStore.interviews,(interviews)=>{
    console.log('change')
    uploadedButNotTranscribedCount.value=interviewStore.interviews.filter(i => i.uploadedFileState == InterviewUploadedFileState.UPLOADED && i.transcriptState!=InterviewTranscriptState.FINISHED).length
    //if(!interviewStore.uploadProgressCounter) return;
    interviews.forEach((interview)=>{
        const oldInterview=oldInterviews.value.find(i=> i.id == interview.id)

        console.log('interview',interview);
        console.log('condition',oldInterview && oldInterview.uploadedFileState && oldInterview.uploadedFileState!=interview.uploadedFileState)
    if(oldInterview && oldInterview.transcriptState && oldInterview.transcriptState!=interview.transcriptState){
    if (!progressToastIsShown.value && interview.transcriptState==InterviewTranscriptState.FINISHED) {
        toast.add({
            severity: 'secondary',
            group:"processing"
        })
        progressToastIsShown.value = true;
    }
    else if(interview.transcriptState==InterviewTranscriptState.ERROR_UNKOWN){
        toast.removeGroup('processing')
        progressToastIsShown.value = false;
        toast.add({
            group:"Error",
            severity: 'secondary',
            summary: `We encountered a problem with ${interview.interviewee} interview`,
            detail: interview.errorTranscriptionMessage?`Reason: ${interview.errorTranscriptionMessage}`:''
        })
    }}
    if(oldInterview && oldInterview.uploadedFileState && oldInterview.uploadedFileState!=interview.uploadedFileState){
        if(interview.uploadedFileState==InterviewUploadedFileState.ERROR_FILE_CORRUPT){
            toast.removeGroup('processing')
            progressToastIsShown.value = false;
            toast.add({
            group:"Error",
            severity: 'secondary',
            summary: `Corrupted file for ${interview.interviewee}`,
            detail: interview.errorFileUploadMessage?`Rason ${interview.errorFileUploadMessage}`:'Check validity and retry'
        });
    }
    }
    })
    
    oldInterviews.value=JSON.parse(JSON.stringify(interviews));
},{deep:true});

watch(() => interviewStore.uploadProgressCounter,(val)=>{
    if(!progressToastIsShown.value && val > 0 ){
    toast.add({
            severity: 'secondary',
            group:"processing"
        })
        progressToastIsShown.value = true;}
});

// watchEffect(()=>{
//     console.log('props.uploadProgress',props.uploadProgress)
//     for(let interviewId in props.uploadProgress){
//         if(interviewStore.interviews?.find(i => i.id == interviewId)?.uploadedFileState == InterviewUploadedFileState.UPLOADED) {
//         toast.remove({ severity: 'custom', summary: 'Uploading your files.', group: 'headless', styleClass: 'backdrop-blur-lg rounded-2xl',interviewId });
//         toastsList.value[interviewId] = false;
//         props.uploadProgress[interviewId]=-1
//     }
//         else if (!toastsList.value[interviewId] && props.uploadProgress[interviewId] != -1){
//         toastsList.value[interviewId]=true;
//         toast.add({ severity: 'custom', summary: 'Uploading your files.', group: 'headless', styleClass: 'backdrop-blur-lg rounded-2xl',interviewId });
//         }
//     }
// })
// const show = () => {
//     if (!visible.value) {
//         toast.add({ severity: 'custom', summary: 'Uploading your files.', group: 'headless', styleClass: 'backdrop-blur-lg rounded-2xl' });
//         visible.value = true;
//         progress.value = 0;

//         if (interval.value) {
//             clearInterval(interval.value);
//         }

//         interval.value = setInterval(() => {
//             if (progress.value <= 100) {
//                 progress.value = progress.value + 20;
//             }

//             if (progress.value >= 100) {
//                 progress.value = 100;
//                 clearInterval(interval.value);
//             }
//         }, 1000);
//     }
// };
</script>
<style>
.transcribed-toast >* > .p-toast-message{
    background-color: white !important;
    box-shadow:
    0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}
.p-toast-message-content > * {
    margin: auto !important;
}
</style>
<style scoped>

.p-dark {
    --body-bg: var(--p-surface-950);
    --body-text-color: var(--p-surface-50);
    --card-border: 1px solid transparent;
    --card-bg: var(--p-surface-900);
    --border-color: rgba(255, 255, 255, 0.1);
    --text-color: var(--p-surface-0);
    --overlay-background: var(--p-surface-900);
}

body {
    margin: 0px;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--body-bg);
    font-weight: normal;
    color: var(--body-text-color);
    padding: 1rem;
}

.card {
    background: var(--card-bg);
    border: var(--card-border);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}
:deep(.p-progressbar .p-progressbar-value) {
    background: #ff596d;
}
</style>