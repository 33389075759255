<template>
  <main>
    <InterviewUploadToast />
    <router-view />
  </main>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import '@fontsource/be-vietnam-pro';
import { useCurrentUser } from 'vuefire';

const route = useRoute();
const user = useCurrentUser();
const claims = ref();

watch(user, (newUser) => {
  if (newUser) {
    newUser.getIdTokenResult(true).then((idTokenResult) => {
      claims.value = idTokenResult.claims;
      console.log('claim getter:' + JSON.stringify(idTokenResult.claims['mindwaves']));
    });
  }
});
</script>
