import { Project } from 'types/project';
import { createWebHistory, createRouter } from 'vue-router';

import { getCurrentUser } from 'vuefire';
import { useSynthesisStore } from './stores/synthesisStore';

const routes = [
  {
    name: 'auth',
    path: '/auth',
    component: () => import('./pages/AuthenticationPage.vue'),
    meta: { unauthenticated: true }
  },
  {
    name: 'signup',
    path: '/sign-up',
    component: () => import('./pages/AuthenticationPage.vue'),
    meta: { unauthenticated: true }
  },
  {
    path: '/',
    component: () => import('./pages/MainLayout.vue'),
    children: [
      {
        path: ':projectId/interviews',
        name: 'projectPage',
        components: {
          main: () => import('./pages/OverviewPage.vue'),
          header: () => import('./components/navbar/NavBar.vue')
        }
      },
      {
        path: ':projectId/synthesis/:id?',
        name: 'synthesisPage',
        components: {
          main: () => import('./pages/SynthesisPage.vue'),
          header: () => import('./components/navbar/NavBar.vue')
        }
      },
      {
        path: ':projectId/interviews/:id',
        name: 'interviewPage',
        components: {
          main: () => import('./pages/InterviewPage.vue'),
          header: () => import('./components/navbar/NavBarInterview.vue')
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to) => {
  if (to.meta.unauthenticated) return true;

  const user = await getCurrentUser();
  if (!user && to.redirectedFrom?.name != 'signup') {
    return '/auth';
  }
});

router.beforeResolve(async (to) => {
  if (to.name == 'auth' || to.name == 'signup') return true;
  console.log('before resolve first routing')
  await useWorkspaceStore().getWorkspace();
  // console.log(useWorkspaceStore().workspace.id)
  if (to.path == '/new/interviews') return true;
  const projectStore = useProjectStore();
  const interviewStore = useInterviewStore();
  await projectStore.getprojectList();
  if (!projectStore.projects.length) return 'new/interviews';
  let currentProject = new Project();
  if (to.params && to.params.projectId)
    currentProject =
      projectStore.projects.find((p) => p.id == to.params.projectId) ?? new Project();
  else if (projectStore.projects.length && projectStore.projects[0].id)
    currentProject = projectStore.projects[0];

  if (to.params?.projectId != currentProject.id) return '/' + currentProject.id + '/interviews';

  await projectStore.subscribeToProject(currentProject.id);
  if (to.name == 'projectPage') {
    await interviewStore.getInterviews(currentProject.id);
    await useSynthesisStore().subToQuestions()
  }
  else if (to.name == 'interviewPage' && to.params.id)
    await interviewStore.setCurrentInterview(to.params.id as string);
  else if (to.name == 'synthesisPage') {
    await useSynthesisStore().subToQuestions()
    const questions = useSynthesisStore().questions
    if (questions.length && !to.params.id)
      return `/${to.params.projectId}/synthesis/${questions[0].id}`
  }
  return true;
});
export default router;
